<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'users' }">Users</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Name, Email, Mobile"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>User Type</b></label>
                  <select
                    name=""
                    id=""
                    v-model="user_type"
                    class="form-control form-control-sm"
                  >
                    <option value="all">All</option>
                    <option value="SUPERUSER">SUPERUSER</option>
                    <option value="TEACHER">TEACHER</option>
                    <option value="STUDENT">STUDENT</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label for=""><b>Status</b></label>
                  <select
                    name=""
                    id=""
                    v-model="status"
                    class="form-control form-control-sm"
                  >
                    <option value="all">All</option>
                    <template v-if="lookupsSelector && lookupsSelector.USER_STATUS">
                      <template
                        v-for="(item, idx) in lookupsSelector.USER_STATUS.codes"
                        :key="idx"
                      >
                        <option :value="item.code">
                          {{ item.description }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add User
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th
                    :class="{
                      active_sort: sort_by == 'name' || sort_by == '-name',
                    }"
                    @click="sortBy('name')"
                  >
                    FULL NAME
                    <i
                      :class="{
                        'fa-sort': sort_by != 'name',
                        'fa-sort-up': sort_by == 'name',
                        'fa-sort-down': sort_by == '-first_name',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'email' || sort_by == '-email',
                    }"
                    @click="sortBy('email')"
                  >
                    EMAIL ADDRESS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'email',
                        'fa-sort-up': sort_by == 'email',
                        'fa-sort-down': sort_by == '-email',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'mobile' || sort_by == '-mobile',
                    }"
                    @click="sortBy('mobile')"
                  >
                    MOBILE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'mobile',
                        'fa-sort-up': sort_by == 'mobile',
                        'fa-sort-down': sort_by == '-mobile',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'user_id' || sort_by == '-user_id',
                    }"
                    @click="sortBy('user_id')"
                  >
                    USER TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'user_id',
                        'fa-sort-up': sort_by == 'user_id',
                        'fa-sort-down': sort_by == '-user_id',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'created_on' || sort_by == '-created_on',
                    }"
                    @click="sortBy('created_on')"
                  >
                    CREATED ON
                    <i
                      :class="{
                        'fa-sort': sort_by != 'created_on',
                        'fa-sort-up': sort_by == 'created_on',
                        'fa-sort-down': sort_by == '-created_on',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td
                        @click="
                          selectedProduct === item.id
                            ? (selectedProduct = null)
                            : (selectedProduct = item.id)
                        "
                      >
                        <span
                          class="fa txt-grey"
                          :class="{
                            'fa-chevron-right': selectedProduct !== item.id,
                            'fa-chevron-down': selectedProduct === item.id,
                          }"
                        ></span>
                        {{item.user_id}} - {{ item.first_name }} {{ item.last_name }}
                      </td>
                      <td>
                        <b>{{ item.email }}</b>
                      </td>
                      <td>{{ item.mobile }}</td>
                      <td>{{ item.user_type }}</td>
                      <td>{{$filters.fullDateTime(item.created_on)}}</td>
                      <td>
                        <span
                          class="text-success"
                          v-if="item.status === 'ACTIVE'"
                          ><b>ACTIVE</b></span
                        >
                        <span class="text-primary" v-else
                          ><b>{{ item.status }}</b></span
                        >
                        <!-- <span
                          v-if="
                            item.user_type !== 'SUPERUSER' &&
                            item.user_type !== 'superuser'
                          "
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        /> -->
                        <span
                          v-if="
                            item.user_type !== 'SUPERUSER' &&
                            item.user_type !== 'superuser'
                          "
                          class="fa fa-external-link edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="$router.push(`/user/${item.id}/`)"
                        />
                      </td>
                    </tr>
                    <tr v-if="selectedProduct === item.id">
                      <td
                        colspan="6"
                        style="background-color: #f5f7fa"
                        class="pad-5"
                      >
                        <user-detail
                          @updated="getdataList()"
                          :user="item"
                        ></user-detail>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-if="loading_list">
                  <tr v-for="i in 6" :key="i">
                    <td v-for="i in 7" :key="i">
                      <div class="loading-card pad-left-30">
                        <div class="block1">
                          <br />
                          <div class="block2" />
                          <div class="block3" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr
                  v-if="
                    dataList.results &&
                    dataList.results.length == 0 &&
                    loading_list == false
                  "
                  class="pagin-block"
                >
                  <td colspan="6" class="text-center pad-20">
                    <br />
                    <img
                      src="/search-in-list.png"
                      style="max-width: 50%"
                    /><br />
                    <h5><b>No results found.</b></h5>
                    <br />
                  </td>
                </tr>
              </tbody>
              <tbody
                v-if="
                  dataList.results &&
                  dataList.results.length > 0 &&
                  loading_list == false
                "
              >
                <tr class="pagin-block">
                  <td colspan="7">
                    <div class="row">
                      <div class="col-md-4">
                        <label for
                          >&nbsp; Showing {{ dataList.start }} to
                          {{
                            parseInt(dataList.start - 1) + parseInt(pageSize) >=
                            dataList.count
                              ? dataList.count
                              : parseInt(dataList.start - 1) +
                                parseInt(pageSize)
                          }}
                          of {{ dataList.count }} Items</label
                        >
                      </div>
                      <div class="col-md-4 text-center">
                        <button
                          :disabled="dataList.previous === null"
                          class="pagin-button"
                          @click="getdataList(dataList.previous)"
                        >
                          <span class="fa fa-chevron-left" />
                        </button>
                        <button
                          :disabled="dataList.next === null"
                          class="pagin-button"
                          @click="getdataList(dataList.next)"
                        >
                          <span class="fa fa-chevron-right" />
                        </button>
                      </div>
                      <div class="col-md-4 text-right">
                        <label for>Items per page:</label>
                        <select
                          v-model="pageSize"
                          class="pagin-select"
                          @change="getdataList()"
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="2000">2000</option>
                        </select>
                        &nbsp;
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="parent margin-top-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">Add New User</h4>
                <h4 v-if="payload.id" class="text-center">Update User</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>First Name</b></label>
                      <input
                        type="text"
                        v-model="payload.first_name"
                        class="form-control form-control-sm"
                      />
                      <span class="error" v-if="error && error.first_name">{{
                        error.first_name
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Last Name</b></label>
                      <input
                        type="text"
                        v-model="payload.last_name"
                        class="form-control form-control-sm"
                      />
                      <span class="error" v-if="error && error.last_name">{{
                        error.last_name
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Email</b></label>
                      <input
                        type="email"
                        v-model="payload.email"
                        class="form-control form-control-sm"
                      />
                      <span class="error" v-if="error && error.email">{{
                        error.email
                      }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Password</b></label>
                      <input type="password" autocomplete="false" v-model="payload.password" class="form-control form-control-sm">
                      <span class="error" v-if="error && error.password">{{error.password}}</span>
                    </div>
                  </div> -->
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Mobile Number</b></label>
                      <input
                        type="text"
                        v-model="payload.mobile"
                        class="form-control form-control-sm"
                      />
                      <span class="error" v-if="error && error.mobile">{{
                        error.mobile
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Gender</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.gender"
                        class="form-control form-control-sm"
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <template v-if="lookupsSelector && lookupsSelector.USER_STATUS">
                          <template
                            v-for="(item, idx) in lookupsSelector.USER_STATUS.codes"
                            :key="idx"
                          >
                            <option :value="item.code">
                              {{ item.description }}
                            </option>
                          </template>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.user_type"
                        class="form-control form-control-sm"
                      >
                        <option value="TEACHER">TEACHER</option>
                        <option value="STUDENT">STUDENT</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New User
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update User
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserDetail from "./components/UserDetail";
import { mapState } from "vuex";
export default {
  components: {
    UserDetail,
  },
  data() {
    return {
      dataList: {
        results: [],
      },
      selectedProduct: null,
      loading_list: false,
      pageSize: 20,
      sort_by: "-created_on",
      search_term: "",
      tab: "users",
      user_type: "all",
      status: "all",
      payload: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile: "",
        user_type: "STUDENT",
        gender: "Male",
        status: "ACTIVE",
      },
      visible_form: false,
      error: {},
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      lookups: (state) => state.lookups,
    }),
  },
  created() {
    this.getdataList();
  },
  methods: {
    getdataList(page = null) {
      const that = this;
      let params = {
        sort: that.sort_by,
        search: that.search_term,
      };
      that.user_type != "all" ? (params.user_type = this.user_type) : null;
      that.status != "all" ? (params.status = this.status) : null;
      that.$cs.user
        .list({
          resource: page,
          pageSize: that.pageSize,
          page,
          params,
        })
        .then(
          (data) => {
            that.dataList = Object.assign(data.data);
          },
          () => {
            console.log("error");
          }
        );
    },
    sortBy(field) {
      if (field === this.sort_by || this.sort_by === `-${field}`) {
        if (!this.sort_by.startsWith("-")) {
          this.sort_by = `-${field}`;
        } else {
          this.sort_by = field;
        }
      } else {
        this.sort_by = field;
      }
      this.getdataList();
    },
    clearFilter() {
      this.user_type = "all";
      this.status = "all";
      this.search_term = "";
      this.getdataList();
    },
    validateData() {
      const that = this;
      that.error = {};
      const isValidEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var proceed = true;
      if (that.payload.first_name === null || that.payload.first_name === "") {
        this.error.first_name = "First Name is required";
        proceed = false;
      }
      // if (that.payload.email === null || that.payload.email === "") {
      //   this.error.email = "Email address is required";
      //   proceed = false;
      // }
      // if (!isValidEmail.test(String(this.payload.email).toLowerCase())) {
      //   this.error.email = "Enter a valid email address";
      //   proceed = false;
      // }
      if (that.payload.password === null || that.payload.password === "") {
        this.error.password = "Password is required";
        proceed = false;
      }
      if (that.payload.mobile === null || that.payload.mobile === "") {
        this.error.mobile = "Mobile number is required";
        proceed = false;
      }
      return proceed;
    },
    newData() {
      const that = this;
      if (this.validateData()) {
        that.processing = true;
        that.$cs.user.create({ data: that.payload }).then(
          (res) => {
            that.getdataList();
            that.error = {};
            that.visible_form = false;
          },
          (err) => {
            if (err.response.status === 400) {
              that.error = err.response.data;
            }
          }
        );
      }
    },
    updateData() {
      const that = this;
      if (this.validateData()) {
        that.$cs.user
          .update({
            data: that.payload,
            resource: `${that.$cs.user.api}${that.payload.id}/`,
          })
          .then(
            (res) => {
              that.getdataList();
              that.error = {};
              that.visible_form = false;
            },
            (err) => {
              if (err.response.status === 400) {
                that.error = err.response.data;
              }
            }
          );
      }
    },
    resetPayload() {
      this.payload = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile: "",
        user_type: "STUDENT",
        gender: "Male",
        status: "ACTIVE",
      };
      this.error = {};
    },
    dataEdit(item) {
      this.payload = Object.assign({}, item);
      this.visible_form = true;
    },
  },
};
</script>
<style lang="stylus" scoped></style>